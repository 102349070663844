/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import {
	Modal,
	Box,
	Button,
	Divider,
	Paper,
	Slide,
	Grid,
	Typography,
	IconButton,
	FormHelperText,
	FormControlLabel,
	Radio,
	RadioGroup,
	FormControl,
	MenuItem,
	Select,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es'; // Import the Spanish locale
import 'dayjs/locale/fr'; // Import the French locale
import 'dayjs/locale/en'; // Import the English locale

import { Form, Formik, ErrorMessage, FormikProps } from 'formik';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

import { fetchSearchUsers } from 'app/entities/Users/usersApiService';
import { useAuth } from '../../../../AuthContext';
import { RootState, AppDispatch } from '../../../redux/store';
import { defaultUserMembershipValue } from '../../../model/membership-model';
import { FormTextField, FormSelectField } from 'app/shared/atoms';
import {
	createUserMembership,
	updateUserMembership,
	fetchUserMembershipsList,
	fetchMembershipsList,
} from '../membershipsApiService';
import { verifyCoupon } from 'app/entities/Coupons/couponsApiService';
import { UsersMultiSelect, NewUsersMultiSelect } from 'app/shared/molecules';
import { AddUserMembershipValidationSchema } from 'app/shared/validations/MembershipValidationSchema';
import { cycleTypes, BookingPaymentType } from 'app/config/constants';
import ConfirmDialog from 'app/shared/components/ConfirmDialog';
import { fetchClubsList } from 'app/entities/Clubs/clubsApiService';
import PaginatedAutocomplete from 'app/shared/molecules/PaginatedAutocomplete';
import { SubmitButton } from "app/shared/molecules";

interface ModalFormProps {
	open: boolean;
	onClose: () => void;
	formData?: any;
	callback?: any;
	action?: any;
}

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};

const BoldTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold', // Set the font weight to bold
}));

const currentDate = moment().utc().toISOString();
let endOfMonth: any = moment().utc().add(1, 'month').hour(23).minute(59).second(59);
endOfMonth = endOfMonth.subtract(1, 'day').subtract(30, 'minutes').toISOString();
let endOfYear: any = moment().utc().add(1, 'year').hour(23).minute(59).second(59);
endOfYear = endOfYear.subtract(1, 'day').subtract(30, 'minutes').toISOString();

let initialValue = {
	...defaultUserMembershipValue,
	membershipStart: currentDate,
	membershipExpiry: endOfMonth,
};

const CycleTypesRadio = ({ values, setFieldValue, membershipList, onChange, t, disabled=false }: any) => {
	const handleChange = (event: any) => {
		if (onChange) {
			onChange(parseInt(event.target.value));
		}
	};

	return (
		<>
			<RadioGroup
				aria-labelledby="radio-buttons-group-label"
				defaultValue={1}
				name="cycleTypes"
			>
				<Grid container spacing={2} alignItems="center" sx={{ justifyContent: 'flex-end' }}>
					{membershipList?.map((x: any, key: number) => (
						<Grid item xs={5} key={`${key}-key`}>
							<FormControlLabel
								value={x?.id}
								control={<Radio />}
								label={t(x?.label)}
								checked={values?.cycleTypes === x?.id}
								onChange={handleChange}
								disabled={disabled}
							/>
						</Grid>
					))}
				</Grid>
			</RadioGroup>
		</>
	);
};

const AddUserMembershipModalForm: React.FC<ModalFormProps> = ({
	open,
	onClose,
	formData,
	callback,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useAuth();
	const clubsList: any = useSelector((state: RootState) => state?.clubs?.clubsData);



	const formikRef = useRef<FormikProps<any>>(null);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [datePickerFromOpen, setDatePickerFromOpen] = useState(false);
	const [datePickerToOpen, setDatePickerToOpen] = useState(false);

	const settingsData: any = localStorage.getItem('settings')
		? JSON.parse(localStorage.getItem('settings') as string)
		: null;
	let percentage = 0;

	const [editData, setEditData] = useState<any>();
	const [selectedMembershipObj, setSelectedMembershipObj] = useState<any>();
	const [membershipPlans, setMembershipPlans] = useState([]);
	const [membershipCycleTypes, setMembershipCycleTypes] = useState(cycleTypes);
	const [appliedCoupon, setAppliedCoupon] = useState<any>();

	useEffect(() => {
		if (open) {
			dispatch(
				fetchMembershipsList(
					{
						pageSize: 1000,
						page: 1,
					},
					(data: any) => {
						setMembershipPlans(data);
					}
				)
			);
			//if(isCoachPortal) {
			dispatch(fetchClubsList({ limit: 1000, page: 1 }));
			//}
		}

		return () => {
			setMembershipPlans([]);
		};
	}, [open, dispatch]);

	// Set all the form data used for building the request object to the API finally
	useEffect(() => {
		if (formData?._id) {
			setEditData({
				...formData,
				...formData?.membershipDetail,
				cycleTypes:
					formData?.membershipDetail?.membershipPlan === 'monthly'
						? 1
						: formData?.membershipDetail?.membershipPlan === 'yearly'
						? 2
						: null,
				user: formData,
				paymentType: formData?.membershipDetail?.paymentType,
				paidAmount: (formData?.membershipDetail?.paidAmount === null || formData?.membershipDetail?.paidAmount === undefined) ? formData?.membershipDetail?.price : formData?.membershipDetail?.paidAmount || formData?.membershipDetail?.amountPaid || 0
			});
		}
	}, [formData]);


	useEffect(() => {
		if (formData?._id && membershipPlans?.length) {
			let newMembership: any = membershipPlans?.find(
				(x: any) => x?._id === formData?.membershipDetail?.membership
			);

			setSelectedMembershipObj(newMembership);

			let newCycleTypes: any = cycleTypes;

			newCycleTypes = newCycleTypes?.filter(
				(x: any) =>
					(newMembership?.monthlyPrice > 0 && x?.id === 1) ||
					(newMembership?.yearlyPrice > 0 && x?.id === 2)
			);

			setMembershipCycleTypes(newCycleTypes);
		} else {
			setMembershipCycleTypes(cycleTypes);
		}
	}, [membershipPlans, formData?._id]);

	/**
	 * Handle Next on button click to load the next step if second page call the api
	 *
	 * @function
	 * @returns void
	 */
	const handleSubmit = async (values?: any, actions?: any) => {
    actions.setSubmitting(true);

    try {
      if (values?.selectedMembershipObj) delete values.selectedMembershipObj;
      if (formData?._id) {
        delete values._id; 
          const response = await dispatch(updateUserMembership(formData?._id, {     
            "membershipStart": values?.membershipStart,
            "membershipExpiry": values?.membershipExpiry,
            "amountPaid" :  values?.paidAmount,
            "paidAmount" :  values?.paidAmount,
            "balance" :  values?.balance,
            "paymentType" :  values?.paymentType  
          }));
  
        if (response) {
          if (typeof callback === "function") {
            callback(); 
          }
            onClose();
        }
      } else {
        const response = await dispatch(createUserMembership({ ...values }));
        if (response) {
          dispatch(fetchUserMembershipsList({ page: 1 })); 
          onClose(); 
        }
      }
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      actions.setSubmitting(false); 
    }
	};

	/**
	 * Handle close on button click
	 *
	 * @function
	 * @returns void
	 */
	const handleCloseModal = (event?: any, reason?: any) => {
		if (formikRef?.current?.dirty) {
			// Show confirmation dialog if there are unsaved changes
			setConfirmOpen(true);
		} else {
			onClose();
		}
	};

	// Handle close confirmation dialog
	const handleCancelClose = () => {
		setConfirmOpen(false);
	};

	// Handle confirm close action
	const handleConfirmClose = () => {
		setConfirmOpen(false);
		onClose();
	};

	const calculateAmount = (values: any, setFieldValue: any) => {
		let calBalanceAmount = parseFloat(values?.price) - parseFloat(values?.paidAmount || 0);

		setFieldValue('balance', calBalanceAmount || 0);
	};

	const handleDateChange = (objectKey: string, setFieldValue: any) => (date: Dayjs | null) => {
		if (date) {
			const formattedDate = dayjs(date).format('YYYY-MM-DD');
			setFieldValue(objectKey, formattedDate);
		} else {
			setFieldValue(objectKey, null);
		}
	};

	const fetchUserOptions = async (inputValue: string, page: number) => {
		try {
			const response: any = await fetchSearchUsers(inputValue, page, true);

			const normalizedData = response?.data?.map((x: any) => ({
				...x,
				id: x?.user?._id || x?._id,
			}));

			return {
				...response,
				data: normalizedData || [],
			};
		} catch (error) {
			console.error('Error fetching user options:', error);
			return {
				data: [],
			};
		}
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				closeAfterTransition
				slotProps={{
					backdrop: {
						style: {
							backgroundColor: 'rgba(0,0,0,0.5)',
						},
					},
				}}
			>
				<Slide in={open} direction="left">
					<Paper
						style={{
							position: 'absolute',
							right: '0',
							transform: 'translateY(-50%)',
							width: '35%',
							padding: '25px',
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Box>
							{/* Content of the sliding modal */}
							<BoldTypography variant="h6" style={{ marginBottom: '1rem' }}>
								{formData?._id
									? t('editUserMembership')
									: t('addNewUserMembership')}
							</BoldTypography>
							<Divider />
							<IconButton
								edge="end"
								color="inherit"
								onClick={handleCloseModal}
								aria-label="close"
								sx={{
									position: 'absolute',
									top: '15px',
									right: '24px',
									cursor: 'pointer',
								}}
							>
								<CloseIcon />
							</IconButton>
							<div>
								<Formik
									innerRef={formikRef}
									initialValues={formData?._id ? editData : initialValue}
									onSubmit={(values, actions) => {
										handleSubmit(
											{
												...values,
												paidAmount: parseFloat(values?.paidAmount),
												amountPaid: parseFloat(values?.paidAmount),
												balance: parseFloat(values?.balance),
												price: parseFloat(values?.price),
												clubs: values?.clubs || [],
												createdBy: user?.userId,
												user: values?.user?._id,
											},
											actions
										);
									}}
									enableReinitialize
									validationSchema={AddUserMembershipValidationSchema()}
								>
									{({
										values,
										errors,
										setFieldValue,
										touched,
										submitCount,
                    isSubmitting,
										...props
									}: any) => {
										return (
											<>
												<Form>
													<Grid
														container
														spacing={2}
														style={{ marginTop: '10px' }}
													>
														<Grid item xs={12}>
															<Box>
																<PaginatedAutocomplete
																	label={t('user')}
																	handleUserSelect={(
																		event: any,
																		val: any
																	) => {
																		setFieldValue('user', val);
																	}}
																	fetchOptions={fetchUserOptions}
																	selectedUser={
																		values?.user
																			? values?.user
																			: ''
																	}
																	//handleAddUserClick={handleAddUserClick}
																	t={t}
																	disabled={formData?._id}
																/>

																{/* <UsersMultiSelect
                                label={t("user")}
                                value={values?.user ? values?.user : []} // Pass the value from Formik's state
                                onChange={(user: any) => {
                                  setFieldValue("user", user);
                                }}
                                disabled={formData?._id}
                                isShowAdminUsers={false}
                                listAll={true}
                              /> */}
															</Box>
															<FormHelperText>
																{touched?.user && errors?.user ? (
																	<span
																		className="MuiFormHelperText-root Mui-error"
																		style={{ color: '#d32f2f' }}
																	>
																		{errors?.user}
																	</span>
																) : (
																	''
																)}
															</FormHelperText>
														</Grid>{' '}
														<Grid item xs={12}>
															<FormSelectField
																label={t('club')}
																name="club"
																options={
																	(Array.isArray(clubsList)
																	  ? clubsList?.map((x: any) => ({
																		  label: x?.name,
																		  name: x?.name,
																		  value: x?._id,
																		}))
																	  : []) || []
																  }
																onChangeCallback={(
																	value: any,
																) => {
																	const clubObj: any = clubsList?.find(
																		(x: any) => x?._id === value
																	  );

																	setFieldValue('club', value);
																	setFieldValue('clubName', clubObj?.name||'');
																}}
															/>
														</Grid>
														<Grid item xs={12}>
															<Box>
																<FormSelectField
																	label={t('membershipPlan')}
																	name="membership"
																	options={membershipPlans
																		?.filter(
																			(x: any) =>
																				x?.status ===
																				'active'
																		)
																		?.map(
																			(x: any) =>
																				(x = {
																					...x,
																					label: x?.name,
																					value: x?._id,
																				})
																		)}
																	onChangeCallback={(
																		value: any,
																		membershipObj: any
																	) => {
																		let findMembership: any =
																			membershipPlans?.find(
																				(x: any) =>
																					x?._id === value
																			);
																		setFieldValue(
																			'selectedMembershipObj',
																			findMembership
																		);
																		setSelectedMembershipObj(findMembership);
																		setFieldValue(
																			'category',
																			findMembership?.category
																				?._id
																		);
																		setFieldValue(
																			'categoryName',
																			findMembership?.category
																				?.name
																		);
																		setFieldValue(
																			'name',
																			findMembership?.name
																		);

																		let price =
																			values?.cycleTypes === 1
																				? findMembership?.monthlyPrice
																				: findMembership?.yearlyPrice;

																		// let tax = percentage
																		// 	? (percentage / 100) *
																		// 	  amountPaid
																		// 	: 0;

																		// let amountPaidWithTax =
																		// 	amountPaid + tax;

																		if (values?.cycleTypes) {
																			setFieldValue(
																				'price',
																				price
																			);
																		}
																	}}
																	disabled={
																		membershipPlans?.length ===
																			0 || formData?._id
																	}
																/>
															</Box>
														</Grid>
														<Grid item xs={12}>
															<Grid
																container
																spacing={2}
																alignItems="center"
																style={{ marginBottom: '10px' }}
															>
																<Grid item xs={4}>
																	<Typography
																		variant="body2"
																		className="uppercase label"
																		gutterBottom
																	>
																		{t('cycleType')}
																	</Typography>
																</Grid>
																<Grid item xs={7.5}>
																	<CycleTypesRadio
																		t={t}
																		values={values}
																		setFieldValue={
																			setFieldValue
																		}
																		membershipList={
																			membershipCycleTypes
																		}
																		disabled={formData?._id? true:false}
																		onChange={(
																			newValue: any
																		) => {
																			setFieldValue(
																				'cycleTypes',
																				newValue
																			);

																			let price =
																				newValue === 1
																					? values
																							?.selectedMembershipObj
																							?.monthlyPrice
																					: values
																							?.selectedMembershipObj
																							?.yearlyPrice;

																			// let tax = percentage
																			// 	? (percentage /
																			// 			100) *
																			// 	  amountPaid
																			// 	: 0;

																			// let amountPaidWithTax =
																			// 	amountPaid + tax;

																			setFieldValue(
																				'price',
																				price
																			);
																			
																			setFieldValue(
																				'membershipPlan',
																				newValue === 1
																					? 'monthly'
																					: 'yearly'
																			);

																			if (newValue === 1) {
																				setFieldValue(
																					'membershipStart',
																					currentDate
																				);
																				setFieldValue(
																					'membershipExpiry',
																					endOfMonth
																				);
																			} else {
																				setFieldValue(
																					'membershipStart',
																					currentDate
																				);
																				setFieldValue(
																					'membershipExpiry',
																					endOfYear
																				);
																			}
																		}}
																	/>
																</Grid>
																<Grid item xs={12}>
																	<FormHelperText>
																		{touched?.user &&
																		errors?.cycleTypes ? (
																			<span
																				className="MuiFormHelperText-root Mui-error"
																				style={{
																					color: '#d32f2f',
																				}}
																			>
																				{errors?.cycleTypes}
																			</span>
																		) : (
																			''
																		)}
																	</FormHelperText>
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={12}>
															<Grid container spacing={2}>
																<Grid item xs={6}>
																	{' '}
																	<Typography
																		sx={{
																			flex: 1,
																			fontSize: '12px',
																			textTransform:
																				'uppercase',
																		}}
																		className="label"
																	>
																		{t('fromDate')}
																	</Typography>
																	<LocalizationProvider
																		dateAdapter={AdapterDayjs}
																		adapterLocale={
																			localStorage.getItem(
																				'language'
																			) || 'en'
																		}
																	>
																		<FormControl
																			fullWidth
																			style={{
																				marginTop: '10px',
																			}}
																		>
																			<DatePicker
																				onChange={handleDateChange(
																					'membershipStart',
																					setFieldValue
																				)}
																				//timezone="UTC"
																				format="DD/MM/YYYY"
																				value={
																					values?.membershipStart
																						? dayjs(
																								values?.membershipStart,
																								'YYYY-MM-DD'
																						  )
																						: null
																				}
																				open={
																					datePickerFromOpen
																				}
																				onOpen={() =>
																					setDatePickerFromOpen(
																						true
																					)
																				}
																				onClose={() =>
																					setDatePickerFromOpen(
																						false
																					)
																				}
																				slotProps={{
																					textField: {
																						onClick:
																							() =>
																								setDatePickerFromOpen(
																									true
																								),
																					},
																				}}
																			/>
																		</FormControl>
																	</LocalizationProvider>
																	<ErrorMessage
																		name={`membershipStart`}
																		component="div"
																		className="error-message"
																		render={(msg: any) => (
																			<span
																				className="MuiFormHelperText-root Mui-error"
																				style={{
																					color: '#d32f2f',
																					marginRight:
																						'10px',
																					fontSize:
																						'12px',
																				}}
																			>
																				{msg}
																			</span>
																		)}
																	/>
																</Grid>{' '}
																<Grid item xs={6}>
																	{' '}
																	<Typography
																		sx={{
																			flex: 1,
																			fontSize: '12px',
																			textTransform:
																				'uppercase',
																		}}
																		className="label"
																	>
																		{t('toDate')}
																	</Typography>
																	<LocalizationProvider
																		dateAdapter={AdapterDayjs}
																		adapterLocale={
																			localStorage.getItem(
																				'language'
																			) || 'en'
																		}
																	>
																		<FormControl
																			fullWidth
																			style={{
																				marginTop: '10px',
																			}}
																		>
																			<DatePicker
																				onChange={handleDateChange(
																					'membershipExpiry',
																					setFieldValue
																				)}
																				format="DD/MM/YYYY"
																				//timezone="UTC"
																				value={
																					values?.membershipExpiry
																						? dayjs(
																								values?.membershipExpiry,
																								'YYYY-MM-DD'
																						  )
																						: null
																				}
																				open={
																					datePickerToOpen
																				}
																				onOpen={() =>
																					setDatePickerToOpen(
																						true
																					)
																				}
																				onClose={() =>
																					setDatePickerToOpen(
																						false
																					)
																				}
																				slotProps={{
																					textField: {
																						onClick:
																							() =>
																								setDatePickerToOpen(
																									true
																								),
																					},
																				}}
																			/>
																		</FormControl>
																	</LocalizationProvider>
																	<ErrorMessage
																		name={`membershipExpiry`}
																		component="div"
																		className="error-message"
																		render={(msg: any) => (
																			<span
																				className="MuiFormHelperText-root Mui-error"
																				style={{
																					color: '#d32f2f',
																					marginRight:
																						'10px',
																					fontSize:
																						'12px',
																				}}
																			>
																				{msg}
																			</span>
																		)}
																	/>
																</Grid>
															</Grid>
														</Grid>
														<Grid item xs={12}>
															<FormControl
																fullWidth
																style={{ marginTop: '15px' }}
															>
																<Typography
																	className="uppercase"
																	sx={{
																		fontSize: '12px',
																		color: '#818181',
                                    marginBottom: "0.8rem"
																	}}
																>
																	{t('paymentType')}
																</Typography>
																<Select
																	name="paymentType"
																	value={values?.paymentType||formData?.membershipDetail?.paymentType}
																	//disabled={formData?._id}
																	onChange={(e: any) => {
																		setFieldValue(
																			'paymentType',
																			e?.target?.value || ''
																		);
																	}}
																>
																	<MenuItem
																		value={
																			BookingPaymentType?.CASH
																		}
																	>
																		{t('cash')}
																	</MenuItem>
																	<MenuItem
																		value={
																			BookingPaymentType?.CARD
																		}
																	>
																		{t('creditCard')}
																	</MenuItem>
																</Select>
                                <FormHelperText>
																{submitCount > 0 && errors?.paymentType ? (
																	<span
																		className="MuiFormHelperText-root Mui-error"
																		style={{ color: '#d32f2f' }}
																	>
																		{t('paymentTypeRequired')}
																	</span>
																) : (
																	''
																)}
															</FormHelperText>
															</FormControl>
														</Grid>
														<Grid item xs={12}>
															<FormTextField
																name="coupon"
																label={t('couponName')}
																placeholder={t('enterCoupon')}
																fullWidth
																disabled={
																	formData?._id ||
																	appliedCoupon ||
																	!values?.cycleTypes
																}
															/>
															{!formData?._id && (
																<Box textAlign={'right'}>
																	<Button
																		variant="text"
																		sx={{
																			fontSize: '12px',
																			marginTop: '5px',
																		}}
																		onClick={() => {
																			if (appliedCoupon) {
																				setAppliedCoupon(
																					null
																				);
																				setFieldValue(
																					'coupon',
																					''
																				);

																				let paidAmount =
																					values?.cycleTypes ===
																					1
																						? values
																								?.selectedMembershipObj
																								?.monthlyPrice
																						: values
																								?.selectedMembershipObj
																								?.yearlyPrice;

																				// let tax =
																				// 	(percentage /
																				// 		100) *
																				// 	amountPaid;

																				setFieldValue(
																					'paidAmount',
																					paidAmount || 0
																				);

																				setFieldValue(
																					'price',
																					paidAmount
																				);
																				setFieldValue(
																					'balance',
																					0
																				);
																			} else {
																				dispatch(
																					verifyCoupon(
																						values?.coupon,
																						(data) => {
																							setAppliedCoupon(
																								data
																							);
																							let oldValue: any =
																								values?.price;
																							if (
																								data?.type ===
																								'flat-off'
																							) {
																								oldValue =
																									values?.price - (data?.value || 0);
																							} else {
																								let percentage =
																									values?.price *
																									(data?.value /
																										100);

																								oldValue =
																									values?.price -
																										(percentage ||
																									0);
																							}

																							setFieldValue(
																								'price',
																								oldValue
																							);

																							setFieldValue(
																								'paidAmount',
																								oldValue
																							);

																							setFieldValue(
																								'balance',
																								0
																							);
																						}
																					)
																				);
																			}
																		}}
																		disabled={!values?.coupon}
																	>
																		{appliedCoupon
																			? 'Remove'
																			: 'Apply'}{' '}
																		Coupon
																	</Button>
																</Box>
															)}
														</Grid>
														<Grid item xs={12}>
															<FormTextField
																name="price"
																label={t('amountToPay')}
																fullWidth
																customCallback={(value) => {
																	setFieldValue(
																		'price',
																		value
																	);
																	calculateAmount(
																		{
																			...values,
																			price: value,
																		},
																		setFieldValue
																	);
																}}
																disabled={true}
																showPriceSymbol={true}
															/>
														</Grid>{' '}
														<Grid item xs={12}>
															<FormTextField
																name="paidAmount"
																label={t('paidAmount')}
																fullWidth
																customCallback={(value) => {
																	setFieldValue('paidAmount', value);
																	calculateAmount(
																		{
																			...values,
																			paidAmount: value,
																		},
																		setFieldValue
																	);
																}}
																//disabled={formData?._id}
																showPriceSymbol={true}
															/>
														</Grid>{' '}
														<Grid item xs={12}>
															<FormTextField
																name="balance"
																label={t('balanceAmount')}
																fullWidth
																disabled={formData?._id}
																showPriceSymbol={true}
															/>
														</Grid>
													</Grid>

													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
														}}
													>
														<Grid
															container
															spacing={2}
															style={{ marginTop: '15px' }}
														>
															<Grid
																item
																xs={12}
																container
																justifyContent="flex-end"
															>
																<SubmitButton
																	  type="submit"
																	  disabled={isSubmitting}
																>
																	{formData?._id
																		? t('saveChanges')
																		: t('saveNewMembership')}
																</SubmitButton>
															</Grid>
														</Grid>
													</div>
												</Form>
											</>
										);
									}}
								</Formik>
							</div>
						</Box>
					</Paper>
				</Slide>
			</Modal>

			{/* Confirmation dialog for unsaved changes */}
			{confirmOpen && (
				<ConfirmDialog
					open={confirmOpen}
					onClose={handleCancelClose}
					onConfirm={handleConfirmClose}
				/>
			)}
		</>
	);
};

export default AddUserMembershipModalForm;
