import { Dispatch, AnyAction } from "redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { transformFilters } from "app/shared/util/buildQuery";
import { CUSTOM_ERROR_MSG, HANDLE_ERROR, HTTP_CALL } from "../../config/http";
import { GET, PUT, CLUBS } from "../../config/API.constants";
import { USER_ROLE } from "../../config/constants";
import { showToast } from "../../shared/util/toastHelper";

import { clubsReceived, clubsRequested } from "../../redux/clubsReducer";
import { isEmpty } from "lodash";

type AppDispatch = Dispatch<AnyAction>;

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchClubList =
  (params: any, callback?: (data: any) => void, isFilter = false) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    try {
      const qParams = {
        ...params,
        limit: params?.pageSize,
        sort: params?.sort || "createdAt|DESC",
      };
      // // Construct the filters object from the query parameter
      const filtersString: any = await transformFilters(qParams);
      const filteredSearchParams = new URLSearchParams(filtersString);

      if (!params?.isFilter) dispatch(clubsRequested());

      response = await HTTP_CALL(
        `${CLUBS.LIST_CLUBS}?${filteredSearchParams.toString()}`,
        GET,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      if (typeof callback === "function") {
        callback(apiData);
      }

      if (!isFilter) dispatch(clubsReceived(response?.data));

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };

/**
 * Fetches data from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchDataFromApi = async (
  apiName: string,
  page: any,
  limit: any,
  filter?: any
): Promise<void> => {
  let response: any = {};

  // Ref. https://medium.com/swlh/urlsearchparams-in-javascript-df524f705317
  const queryParams = new URLSearchParams({
    page: page + 1,
    limit,
    sort: "createdAt%7CDESC",
  });

  if (filter) {
    queryParams.set("filters", "status|active");
  }

  const apiUrl = `${apiName}?${queryParams.toString()}`;

  try {
    response = await HTTP_CALL(apiUrl, GET);
    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        return apiData;
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Fetches club detail data based on the club id from the API.
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const fetchClubDetailFromApi = async (id: any, apiName: string) => {
  let response: any = {};

  try {
    response = await HTTP_CALL(`${apiName}${id}`, GET);

    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (!isEmpty(apiData)) {
        return apiData;
      } else {
        showToast("No Data", "Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

/**
 * Update club status
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const clubStatusUpdate = async (id: any, params?: any) => {
  const clubStatusUpdateApi = CLUBS.STATUS_UPDATE;
  let response: any = {};

  try {
    response = await HTTP_CALL(
      `${clubStatusUpdateApi}${id}/status-update`,
      PUT,
      "",
      params
    );

    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (apiData?.message?.startsWith("Unable to deactivate since there are bookings")) {
        return 'reconfirm';
      } else {
        if (!isEmpty(apiData)) {
          showToast("Club status updated successfully", "Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};


/**
 * Update court status
 *
 * @async
 * @function
 * @returns {Promise<void>} A Promise that resolves when the data is fetched.
 */

export const courtStatusUpdate = async (id: any, params?: any) => {
  const courtStatusUpdateApi = CLUBS.COURT_STATUS_UPDATE;
  let response: any = {};

  try {
    response = await HTTP_CALL(
      `${courtStatusUpdateApi}${params?.clubId}/courts/${id}/status-update`,
      PUT,
      "",
      params
    );

    const {
      data: { data: apiData, statusCode, message },
    } = response;

    if (statusCode === 200) {
      if (apiData?.message?.startsWith("Unable to deactivate since there are bookings")) {
        return 'reconfirm';
      } else {
        if (!isEmpty(apiData)) {
          showToast("Court status updated successfully", "Success", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          showToast("No Data", "Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } else {
      CUSTOM_ERROR_MSG(message || statusCode);
    }
  } catch (error: any) {
    HANDLE_ERROR(error);
  }
};

export const fetchClubsList =
  (params: any, callback?: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => any) => {
    let response: any = {};
    const filteredSearchParams = new URLSearchParams(params);

    try {
      response = await HTTP_CALL(
        `${
          params?.userType === USER_ROLE.COACH
            ? CLUBS.COACH_CLUBS_LIST
            : CLUBS.LIST_CLUBS
        }?${filteredSearchParams?.toString()}`,
        GET,
        "",
        params
      );

      const {
        data: { data: apiData, statusCode, message },
      } = response;

      dispatch(clubsReceived(apiData));
      if (typeof callback === "function") {
        callback(apiData);
      }

      if (statusCode === 200) {
        if (!isEmpty(apiData)) {
          return apiData;
        } else {
          // showToast("No Data", "Error", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
      } else {
        CUSTOM_ERROR_MSG(message || statusCode);
      }
    } catch (error: any) {
      HANDLE_ERROR(error);
    }
  };
