import React, { useState, useEffect } from 'react';
import {
	Box,
	Divider,
	Grid,
	Typography,
	Button,
	Select,
	MenuItem,
	FormControl,
	CircularProgress,
	IconButton,
	Stack,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import moment from 'moment';
import { isEmpty } from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'formik';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { FormTextField } from 'app/shared/atoms';
import StyledCustomTextField from 'app/shared/components/StyledTextField';
import { AntSwitch } from 'app/shared/components/BlackSwitch';
import { showToast } from '../../../../shared/util/toastHelper';
import { ActionTypes } from '../../../../config/constants';
import { uploadImageToS3 } from 'app/shared/util/ImageUploadApiService';
//import CourtServices from './CourtServices';
import CourtPrices from './CourtPrices';
import CourtSchedule from './CourtSchedule';

interface CourtsProps {
	courtsData?: any;
	handleChange?: any;
	hasValues?: boolean;
	setHasValues?: any;
	setCourtsData?: any;
	action?: any;
	inde?: number;
	formProps?: any;
	servicesList?: any;
	formData?: any;
	courtIndex?: any;
	courtStatusChange?:any;
}

type Field =
	| 'name'
	| 'image'
	| 'playersAllowed'
	| 'onPeakHourPrice'
	| 'offPeakHourPrice'
	| 'onPeakOneAndHalfHourPrice'
	| 'offPeakOneAndHalfHourPrice'
	| 'onPeakTwoHourPrice'
	| 'offPeakTwoHourPrice'
	| 'status';

const DaysOfWeek = [
	'sunday',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
];

const DefaultWorkingHour = async (t: any) => {
	return {
		day: 'sunday',
		startTime: '00:00',
		endTime: '00:00',
		slots: [],
		peakSlots: [],
		peakHours: [],
		isPeak: false,
		isChecked: false,
	};
};

const initializeWorkingHours = async (
	values: any,
	isEdit?: boolean,
	courtIndex?: any,
	t?: any // Pass the translation function as an argument
) => {
	try {
		// Get the default working hours
		const defaultWorkingHours = await DefaultWorkingHour(t);

		const isPeakExist = values?.courts?.[courtIndex]?.workingHours?.length
			? values.courts[courtIndex].workingHours.some((wh: any) => wh.isPeak)
			: false;

		// If editing, return the existing values
		if (isEdit && courtIndex) {
			return values?.courts?.[courtIndex]?.workingHours || values;
		}

		// If peak hours exist or any working hours are checked, return the existing values
		if (
			isPeakExist ||
			values?.courts?.[courtIndex]?.workingHours?.some((wh: any) => wh.isChecked)
		) {
			return values?.courts?.[courtIndex]?.workingHours;
		}

		// Otherwise, generate the default working hours for each day of the week
		const defaultValues =
			DaysOfWeek?.map((day) => ({
				...defaultWorkingHours,
				day,
				isChecked: false,
				isPeak: false,
				peakHours: [],
				peakSlots: [],
			})) || [];

		return defaultValues;
	} catch (error) {
		console.error('Error initializing working hours:', error);
		return []; // Return an empty array in case of an error
	}
};

const Courts: React.FC<CourtsProps> = ({
	courtsData,
	setCourtsData,
	handleChange,
	hasValues,
	action,
	formProps,
	servicesList,
	formData,
	courtStatusChange
}) => {
	const { t } = useTranslation();
	const { values, errors, setFieldValue } = formProps;
	const [showLoader, setLoader] = useState(false);
	const [defaultWorkingHours, setWorkingHours] = useState([]);

	useEffect(() => {
		const fetchWorkingHours = async () => {
			const hours = await initializeWorkingHours(values, false, 0, t);
			setWorkingHours(hours);
		};

		fetchWorkingHours();
	}, [values, t]);

	const initializeNumCourts = () => {
		if (action === ActionTypes?.CREATE) {
			if (values?.courts?.length === 0) {
				return 1;
			} else {
				return values?.noOfCourts;
			}
		} else if (action === ActionTypes?.EDIT) {
			return values?.courts?.length;
		} else {
			return 1;
		}
	};
	const [numCourts, setNumCourts] = useState(initializeNumCourts);
	const [expanded, setExpanded] = React.useState<string[]>(
		Array.from({ length: numCourts }, (_, index) => `panel${index + 1}`)
	);
	const [isDragActive, setIsDragActive] = useState(false);
	const serviceOptions = servicesList?.map(({ ...rest }: any) => ({
		...rest,
		label: rest?.name,
		value: rest?._id,
		serviceId: rest?._id,
	}));

	useEffect(() => {
		if (values)
			setFieldValue(
				'courts',
				values?.courts?.length > 0
					? values?.courts || []
					: [
							{
								name: undefined,
								image: '',
								playersAllowed: 4,
								onPeakHourPrice: null,
								offPeakHourPrice: null,
								onPeakOneAndHalfHourPrice: null,
								offPeakOneAndHalfHourPrice: null,
								onPeakTwoHourPrice: null,
								offPeakTwoHourPrice: null,
								status: 'active',
								services: [],
								workingHours: defaultWorkingHours,
								priority: 0,
							},
					  ]
			);
	}, [defaultWorkingHours]);

	/**
	 * Handle the number of courts change to display the number of court fields
	 *
	 * @function
	 * @returns void
	 */
	const handleNumCourtsChange = async (event: any) => {
		// Log the original (existing) values before updating
		const oldValues = values?.courts;

		setLoader(true);

		// Create a shallow copy of the current court details to preserve the original state
		const updatedCourtDetails = [...oldValues];
		const existingCourtsCount = updatedCourtDetails?.length;

		// Parse the new number of courts from the event
		const newNumCourts = parseInt(event.target.value, 10);

		// Update the number of courts in the form values and local state
		setFieldValue('noOfCourts', newNumCourts);
		setNumCourts(newNumCourts);

		if (newNumCourts > existingCourtsCount) {
			// Append new courts if the new count is greater
			for (let i = existingCourtsCount; i < newNumCourts; i++) {
				updatedCourtDetails.push({
					name: '',
					image: '',
					playersAllowed: 4,
					onPeakHourPrice: '',
					offPeakHourPrice: '',
					onPeakOneAndHalfHourPrice: '',
					offPeakOneAndHalfHourPrice: '',
					onPeakTwoHourPrice: '',
					offPeakTwoHourPrice: '',
					status: 'active',
					services: [],
					workingHours: await initializeWorkingHours(values, false, i, t),
					priority: i,
				});
			}
		} else if (newNumCourts < existingCourtsCount) {
			// Remove excess courts if the new count is less
			updatedCourtDetails.splice(newNumCourts);
		}

		// Update the court details state and the form field value
		setFieldValue('courts', updatedCourtDetails);

		setLoader(false);

		// Form the court details data with the relevant field and values
		setCourtsData({
			noOfCourts: newNumCourts,
			courts: updatedCourtDetails,
		});
	};

	/**
	 * Handle the Accordian expansion or collapse
	 *
	 * @function
	 * @returns void
	 */
	const handleAccordionChange =
		(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			// setExpanded((prevExpanded) =>
			// 	isExpanded
			// 		? [...prevExpanded, panel]
			// 		: prevExpanded.filter((item) => item !== panel)
			// );
			setExpanded(isExpanded ? [panel] : []);
		};

	const handleSameSchedule = async (e: any, courtIndex: any) => {
		let filterCourts = values?.courts?.filter(
			(x: any, key: number) => key !== courtIndex && x?.name
		);
		setFieldValue(`courts[${courtIndex}].same`, e?.target?.value);
		let selectedCourt = filterCourts[e?.target?.value];
		setFieldValue(`courts[${courtIndex}].showSchedule`, true);
		setFieldValue(`courts[${courtIndex}].workingHours`, selectedCourt?.workingHours);
		setFieldValue(`courts[${courtIndex}].offPeakHourPrice`, selectedCourt?.offPeakHourPrice);
		setFieldValue(`courts[${courtIndex}].onPeakHourPrice`, selectedCourt?.onPeakHourPrice);
		setFieldValue(
			`courts[${courtIndex}].offPeakOneAndHalfHourPrice`,
			selectedCourt?.offPeakOneAndHalfHourPrice
		);
		setFieldValue(
			`courts[${courtIndex}].onPeakOneAndHalfHourPrice`,
			selectedCourt?.onPeakOneAndHalfHourPrice
		);
		setFieldValue(
			`courts[${courtIndex}].offPeakTwoHourPrice`,
			selectedCourt?.offPeakTwoHourPrice
		);
		setFieldValue(
			`courts[${courtIndex}].onPeakTwoHourPrice`,
			selectedCourt?.onPeakTwoHourPrice
		);
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		window.addEventListener(
			'dragover',
			function (e) {
				e.preventDefault();
			},
			false
		);
		window.addEventListener(
			'drop',
			function (e) {
				e.preventDefault();
			},
			false
		);

		setIsDragActive(true);
	};

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		window.addEventListener(
			'dragover',
			function (e) {
				e.preventDefault();
			},
			false
		);
		window.addEventListener(
			'drop',
			function (e) {
				e.preventDefault();
			},
			false
		);
		setIsDragActive(true);
	};

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		window.addEventListener(
			'dragover',
			function (e) {
				e.preventDefault();
			},
			false
		);
		setIsDragActive(false);
	};

	// Define a new function to handle image removal
	const handleRemoveImage = (index: number) => {
		setFieldValue(`courts[${index}].image`, '');
	};

	const showToastMessage = (message: any, type: any) => {
		showToast(message, type, { position: toast.POSITION.TOP_RIGHT });
	};

	const handleDrop = async (
		e: React.DragEvent<HTMLDivElement>,
		courtIndex: any,
		field: Field
	) => {
		e.preventDefault();
		setIsDragActive(false);
		if (e.dataTransfer.files.length > 0) {
			const droppedFile = e.dataTransfer.files[0];
			const selectedFiles = Array.from(e.dataTransfer.files);
			// Filter unwanted file extensions
			const allowedFileExtensions = ['jpg', 'jpeg', 'png'];
			const validFiles = selectedFiles.filter((file) => {
				const fileExtension = file.name.split('.').pop()?.toLowerCase();
				return fileExtension && allowedFileExtensions.includes(fileExtension);
			});

			if (validFiles.length !== selectedFiles.length) {
				// Display error message for invalid file extensions
				showToastMessage(
					'Invalid file format. Please select file format: JPG, JPEG or PNG.',
					'Error'
				);
				return;
			}
			const formData = new FormData();
			formData.append('files', droppedFile);

			try {
				const uploadImageAPIResponse = await uploadImageToS3(formData);

				if (uploadImageAPIResponse.statusCode === 200) {
					const newAttachments = uploadImageAPIResponse?.data?.map(
						(ele: any) => ele?.Location?.Location
					);

					setFieldValue(`courts[${courtIndex}].[${field}]`, newAttachments[0]);

					showToast(t('imageUploadedSuccessfully'), 'Success', {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					// Handle API error
					showToast(t('imageUploadedError'), 'Error', {
						position: toast.POSITION.TOP_RIGHT,
					});
				}

				// Continue with handling API response...
			} catch (error) {
				console.error('Error uploading image:', error);
				// Handle error...
			}
		}
	};

	const handleFileInputChange = async (
		e: React.ChangeEvent<HTMLInputElement>,
		courtIndex: any,
		field: Field
	) => {
		if (e.target.files) {
			const selectedFiles = Array.from(e.target.files);
			// Filter unwanted file extensions
			const allowedFileExtensions = ['jpg', 'jpeg', 'png'];
			const validFiles = selectedFiles.filter((file) => {
				const fileExtension = file.name.split('.').pop()?.toLowerCase();
				return fileExtension && allowedFileExtensions.includes(fileExtension);
			});

			if (validFiles.length !== selectedFiles.length) {
				// Display error message for invalid file extensions
				showToastMessage(
					'Invalid file format. Please select file format: JPG, JPEG or PNG.',
					'Error'
				);
				return;
			}
			try {
				const formData = new FormData();

				for (const fileToUpload of selectedFiles) {
					formData.append('files', fileToUpload);
				}

				const uploadImageAPIResponse = await uploadImageToS3(formData);

				if (uploadImageAPIResponse.statusCode === 200) {
					const newAttachments = uploadImageAPIResponse?.data?.map(
						(ele: any) => ele?.Location?.Location
					);

					// Update the state with the modified value
					setFieldValue(`courts[${courtIndex}][${field}]`, newAttachments?.[0]);
					showToast(t('imageUploadedSuccessfully'), 'Success', {
						position: toast.POSITION.TOP_RIGHT,
					});

					// Trigger file input programmatically to handle smaller screens
					// const fileInput = document.getElementById(
					//   `fileInput-${courtIndex}`
					// ) as HTMLInputElement;
					// if (fileInput) {
					//   fileInput.click();
					// }
				} else {
					// Handle API error
					showToast(t('imageUploadedError'), 'Error', {
						position: toast.POSITION.TOP_RIGHT,
					});
				}
			} catch (error) {
				// Handle error
				showToast(t('imageUploadedError'), 'Error', {
					position: toast.POSITION.TOP_RIGHT,
				});
			}

			e.target.value = '';
		}
	};

	const generateTimeOptions = (minTimeSlot: string) => {
		const startTime = moment(minTimeSlot, 'HH:mm').add(30, 'minutes');
		const startIndex = startTime.diff(moment().startOf('day'), 'minutes') / 30;

		return Array.from({ length: 48 - startIndex }, (_, index) => {
			const time = moment()
				.startOf('day')
				.add((index + startIndex) * 30, 'minutes');
			return (
				<MenuItem key={`time-${index}`} value={time.format('HH:mm')}>
					{time.format('HH:mm')}
				</MenuItem>
			);
		});
	};

	const clubBasedSlots = (minTimeSlot: string = '00:00', maxTimeSlot: string = '24:00') => {
		// Generate the time slots
		const slots = Array.from({ length: 48 }, (_, index) => {
			const time = moment()
				.startOf('day')
				.add(index * 30, 'minutes')
				.format('HH:mm');

			const isWithinRange = time >= minTimeSlot && time <= maxTimeSlot;

			// Return only the slots that are within the specified time range
			return (
				isWithinRange && (
					<MenuItem key={`club-from-${index}`} value={time}>
						{time}
					</MenuItem>
				)
			);
		}).filter(Boolean); // Remove any 'false' or 'undefined' items

		// If no slots are available within the range, add an empty menu item
		if (slots.length === 0) {
			slots.push(
				<MenuItem key="empty" value="" disabled>
					No available slots
				</MenuItem>
			);
		}

		return slots;
	};

	const courtScheduleOptions = (courtIndex: number) => {
		return values?.courts?.length > 0 ? (
			values?.courts
				?.filter(
					(x: any, key: number) =>
						key !== courtIndex &&
						x?.name &&
						x?.workingHours?.length !== 0 &&
						x?.workingHours?.some((wh: any) => wh?.isChecked)
				)
				?.map((x: any, key: number) => (
					<MenuItem value={`${key}`} key={`courts-${key}`}>
						{x?.name || t('court') + (courtIndex + 1)}{' '}
					</MenuItem>
				))
		) : (
			<MenuItem value={''} key={`courts-1`} disabled>
				None
			</MenuItem>
		);
	};

	// Memoize to avoid re-rendering
	// const memoizedCourtPrices = useMemo(() => {
	//   return (
	//     <CourtPrices
	//       court={values.courts}
	//       handleChange={handleChange}
	//       courtIndex={0}
	//     />
	//   );
	// }, [values.courts, handleChange]);

	const memoizedClubBasedSlots = React.useMemo(() => {
		return clubBasedSlots(values?.minTimeSlot, values?.maxTimeSlot);
	}, [values?.minTimeSlot, values?.maxTimeSlot]);

	return (
		<>
			<Grid container spacing={2} sx={{ marginBottom: '1.5rem' }}>
				<Grid item xs={6}>
					<FormControl fullWidth style={{ marginTop: '15px' }}>
						<Typography className="uppercase label" sx={{ marginBottom: '1rem' }}>
							{t('startTime')}
						</Typography>
						<Select
							value={values?.minTimeSlot ?? ''}
							onChange={(e) => {
								const selectedMinTime = e.target.value;
								setFieldValue('minTimeSlot', selectedMinTime);
								if (
									moment(values?.maxTimeSlot, 'HH:mm').isBefore(
										moment(selectedMinTime, 'HH:mm').add(30, 'minutes')
									)
								) {
									setFieldValue(
										'maxTimeSlot',
										moment(selectedMinTime, 'HH:mm')
											.add(30, 'minutes')
											.format('HH:mm')
									);
								}
							}}
							displayEmpty
							inputProps={{ 'aria-label': 'Select min time' }}
							disabled={values?._id}
						>
							{Array.from({ length: 48 }, (_, index) => (
								<MenuItem
									key={index}
									value={moment()
										.startOf('day')
										.add(index * 30, 'minutes')
										.format('HH:mm')}
								>
									{moment()
										.startOf('day')
										.add(index * 30, 'minutes')
										.format('HH:mm')}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					<FormControl fullWidth style={{ marginTop: '15px' }}>
						<Typography className="uppercase label" sx={{ marginBottom: '1rem' }}>
							{t('endTime')}
						</Typography>
						<Select
							value={values?.maxTimeSlot ?? ''}
							onChange={(e) => setFieldValue('maxTimeSlot', e.target.value)}
							displayEmpty
							inputProps={{ 'aria-label': 'Select max time' }}
							disabled={values?._id}
						>
							{generateTimeOptions(values?.minTimeSlot)}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<Typography variant="body2" gutterBottom className="uppercase label">
				{t('noOfCourts')}
			</Typography>
			<StyledCustomTextField
				fullWidth
				name="noOfCourts"
				type="number"
				//value={action === ActionTypes?.CREATE ? numCourts : values?.noOfCourts}
				value={values?.courts?.length}
				onChange={(e: any) => {
					handleNumCourtsChange(e);
				}}
				margin="normal"
				error={hasValues && numCourts === 0}
				helperText={
					hasValues === true && numCourts === 0 ? t('numberOfCourtsRequired') : ''
				}
				disabled
			/>

			{(numCourts === 1 || values?.courts?.length > 0) && (
				<Box mt={2}>
					<Divider />
					<Typography variant="h6" sx={{ margin: '1rem 0' }}>
						{t('courtDetails')}
					</Typography>
					{/* Loader overlay */}
					{showLoader && values?.courts?.length > 0 && (
						<Box
							position="absolute"
							top={0}
							left={0}
							right={0}
							bottom={0}
							display="flex"
							alignItems="center"
							justifyContent="center"
							bgcolor="rgba(255, 255, 255, 0.8)" // Adjust transparency
							zIndex={2} // Ensure the loader is on top
						>
							<CircularProgress />
						</Box>
					)}

					<FieldArray
						name="courts"
						render={(arrayHelpers: any) => (
							<div>
								{values?.courts?.length > 0 &&
									values.courts.map((courtDetail: any, courtIndex: any) => {
										// const isCourtTouched =
										// 	!isEmpty(errors?.courts?.[courtIndex]) &&
										// 	submitCount &&
										// 	touched?.courts?.[courtIndex];

										return (
											<Grid item key={`courtDetail-${courtIndex}`}>
												<Divider />
												<Accordion
													elevation={0}
													expanded={expanded.includes(
														`panel-main-${courtIndex}`
													)}
													onChange={handleAccordionChange(
														`panel-main-${courtIndex}`
													)}
												>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														aria-controls={`panel-main-${courtIndex}-content`}
														id={`panel-main-${courtIndex}-header`}
													>
														<Grid container spacing={2}>
															<Grid item xs={8}>
																<Typography
																	variant="h6"
																	sx={{
																		fontWeight: 600,
																		color: !isEmpty(
																			errors?.values?.[
																				courtIndex
																			]
																		)
																			? '#FF0000!important'
																			: 'unset',
																	}}
																>
																	{courtDetail?.name ||
																		`${t('court')} ${
																			courtIndex + 1
																		}`}
																</Typography>
															</Grid>

															<Grid
																item
																xs={4}
																style={{
																	justifyContent: 'flex-end',
																}}
															>
																{values?.courts?.length !== 1 && !values?.courts?.[courtIndex]?._id && (
																	<IconButton
																		edge="end"
																		color="inherit"
																		onClick={(e: any) => {
																			e.preventDefault();
																			e.stopPropagation();
																			arrayHelpers.remove(
																				courtIndex
																			);
																		}}
																		aria-label="close"
																		sx={{
																			position: 'absolute',
																			right: '50px',
																			top: '10px',
																			cursor: 'pointer',
																			zIndex: 99,
																		}}
																	>
																		<CloseIcon />
																	</IconButton>
																)}
															</Grid>
														</Grid>
													</AccordionSummary>
													<AccordionDetails>
														<Divider />
														<Grid
															container
															spacing={2}
															sx={{ marginTop: '5px' }}
														>
															<Grid
																item
																xs={12}
																onDragOver={handleDragOver}
																onDragEnter={handleDragEnter}
																onDragLeave={handleDragLeave}
																onDrop={(e) =>
																	handleDrop(
																		e,
																		courtIndex,
																		'image'
																	)
																}
															>
																<Typography
																	className="uppercase label"
																	sx={{ marginBottom: '1rem' }}
																>
																	{`${t('COURT IMAGE')} ${t(
																		'optional'
																	)}`}
																</Typography>
																<input
																	type="file"
																	id={`fileInput-${courtIndex}`}
																	accept="image/*"
																	style={{ display: 'none' }}
																	onChange={(e) =>
																		handleFileInputChange(
																			e,
																			courtIndex,
																			'image'
																		)
																	}
																/>
																<label
																	htmlFor={`fileInput-${courtIndex}`}
																	className={
																		isDragActive
																			? 'drag-active'
																			: ''
																	}
																>
																	<Button
																		variant="outlined"
																		component="span"
																		sx={{
																			minHeight: '189px',
																			border: '1px solid #E0E0E0',
																			padding: '51px 117px',
																			textAlign: 'center',
																			borderRadius: '5px',
																			width: '100%',
																			display: 'inline-block',
																		}}
																	>
																		<CloudUploadIcon
																			sx={{
																				marginTop: '35px',
																				color: 'black',
																			}}
																		/>
																		<Typography
																			variant="button"
																			display="block"
																			sx={{
																				fontWeight: 'bold',
																				color: 'black',
																			}}
																		>
																			{t('clickToUpload')}{' '}
																			<span
																				style={{
																					color: 'grey',
																				}}
																			>
																				{t('dragDrop')}
																			</span>
																		</Typography>
																		<br />
																		<Typography
																			variant="body2"
																			display="block"
																			sx={{ color: 'black' }}
																		>
																			{t('youCanUpload')}
																		</Typography>
																	</Button>
																</label>
															</Grid>
														</Grid>

														{courtDetail?.image && (
															<Grid
																container
																spacing={2}
																sx={{ marginTop: '5px' }}
															>
																<Grid item xs={12}>
																	<img
																		src={courtDetail.image}
																		alt={courtDetail.image}
																		style={{
																			maxWidth: '100%',
																			maxHeight: '190px',
																		}}
																	/>
																	<Button
																		variant="outlined"
																		onClick={() =>
																			handleRemoveImage(
																				courtIndex
																			)
																		}
																		sx={{ float: 'right' }}
																	>
																		{t('RemoveImage')}
																	</Button>
																</Grid>
															</Grid>
														)}

														<Typography
															className="uppercase label"
															sx={{
																marginTop: '1rem',
																marginBottom: '1rem',
																color: '#818181',
																fontWeight: 500,
															}}
														>
															{t('courtName')}
														</Typography>
														<Box sx={{ marginTop: '1rem' }}>
															<FormTextField
																fullWidth
																name={`courts[${courtIndex}].name`}
																autoComplete="off"
																value={courtDetail?.name || ''}
																placeholder={t('name')}
															/>
														</Box>

														{/* <CourtServices
															courtIndex={courtIndex}
															formProps={formProps}
															servicesList={serviceOptions}
														/> */}

														{values?.courts?.length > 1 && (
															<Grid
																container
																spacing={2}
																sx={{ marginTop: '5px' }}
																alignItems="center"
															>
																<Grid item xs={6}>
																	<FormControl
																		fullWidth
																		variant="outlined"
																		sx={{ marginTop: '1rem' }}
																	>
																		<Typography
																			sx={{
																				fontWeight: 500,
																				marginBottom:
																					'10px',
																			}}
																		>
																			{t('UseSameScheduleAs')}
																		</Typography>
																		<Select
																			onChange={(e) =>
																				handleSameSchedule(
																					e,
																					courtIndex
																				)
																			}
																			name={`courts[${courtIndex}].same`}
																			value={
																				courtDetail?.same ||
																				''
																			}
																		>
																			{courtScheduleOptions(
																				courtIndex
																			)}
																		</Select>
																	</FormControl>
																</Grid>
															</Grid>
														)}

														<Divider sx={{ marginTop: '1rem' }} />
														<CourtPrices
															courtIndex={courtIndex}
															formProps={formProps}
														/>
														<Divider sx={{ marginTop: '1rem' }} />

														<CourtSchedule
															courtIndex={courtIndex}
															formProps={formProps}
															servicesList={serviceOptions}
															formData={{}}
															setScheduleData={() => {}}
															memoizedClubBasedSlots={
																memoizedClubBasedSlots
															}
														/>

														{values?.courts?.[courtIndex]?._id && (
															<Grid
																item
																xs={12}
																sx={{ marginTop: '1rem' }}
															>
																<Grid container spacing={2}>
																	<Grid
																		item
																		xs={6}
																		className="label uppercase"
																		sx={{ fontSize: '15px' }}
																	>
																		{t('status')}
																	</Grid>
																	<Grid item xs={6}>
																		<Stack
																			direction="row"
																			spacing={1}
																			alignItems="center"
																			sx={{ float: 'right' }}
																		>
																			<Typography
																				sx={{
																					fontSize:
																						'12px',
																					textTransform:
																						'capitalize',
																				}}
																			>
																				{t('inactive')}
																			</Typography>
																			<AntSwitch
																				checked={
																					values
																						?.courts?.[
																						courtIndex
																					]?.status ===
																					'active'
																				}
																				onChange={(e) => {
																					if(
																						values
																							?.courts?.[
																							courtIndex
																						]
																							?._id && values
																							?.courts?.[
																							courtIndex
																						]
																							?.status === 'active'
																					) {
																						courtStatusChange(courtIndex)
																					} else {
																						setFieldValue(
																							`courts[${courtIndex}].status`,
																							values
																								?.courts?.[
																								courtIndex
																							]
																								?.status ===
																								'active'
																								? 'inactive'
																								: 'active'
																						);
																					}
																					// Toggle the status based on the current value
																					// setFieldValue(
																					// 	`courts[${courtIndex}].status`,
																					// 	values
																					// 		?.courts?.[
																					// 		courtIndex
																					// 	]
																					// 		?.status ===
																					// 		'active'
																					// 		? 'inactive'
																					// 		: 'active'
																					// );
																				}}
																			/>
																			<Typography
																				sx={{
																					fontSize:
																						'12px',
																					textTransform:
																						'capitalize',
																				}}
																			>
																				{t('active')}
																			</Typography>
																		</Stack>
																	</Grid>
																</Grid>
															</Grid>
														)}
													</AccordionDetails>
												</Accordion>

												{values?.courts?.length === courtIndex + 1 && (
													<Grid
														container
														spacing={2}
														justifyContent={'center'}
														style={{ marginTop: '10px' }}
													>
														<Grid
															item
															onClick={async () => {
																// Await for the initialization of working hours
																const initializedWorkingHours =
																	await initializeWorkingHours(
																		values,
																		false,
																		courtIndex+1,
																		t
																	);

																arrayHelpers.insert(
																	courtIndex + 1,
																	{
																		name: '',
																		image: '',
																		playersAllowed: 4,
																		onPeakHourPrice: '',
																		offPeakHourPrice: '',
																		onPeakOneAndHalfHourPrice:
																			'',
																		offPeakOneAndHalfHourPrice:
																			'',
																		onPeakTwoHourPrice: '',
																		offPeakTwoHourPrice: '',
																		status: 'active',
																		services: [],
																		workingHours:
																			initializedWorkingHours, // Use the initialized value
																		priority: courtIndex,
																	}
																);

																// Expand the newly added court panel
																setExpanded((prevExpanded) => [
																	...prevExpanded,
																	`panel-main-${courtIndex + 1}`,
																]);
															}}
															style={{
																cursor: 'pointer',
																display: 'flex',
																fontWeight: 500,
															}}
														>
															{t('addCourt')}
															<AddCircleOutlineIcon
																sx={{ marginLeft: '5px' }}
															/>
														</Grid>
													</Grid>
												)}
											</Grid>
										);
									})}
							</div>
						)}
					/>
				</Box>
			)}
		</>
	);
};

export default Courts;
